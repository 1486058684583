import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import classNames from '@utils/classNames'

export interface TimelineProgressProps extends Props {
  progress: number
}

const BACKGROUND_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'bg-orange-200',
  [Theme.GREEN]: 'bg-green-200',
}

const FOREGROUND_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'bg-orange-500',
  [Theme.GREEN]: 'bg-green-500',
}

const TimelineProgress = ({ theme = Theme.ORANGE, progress }: TimelineProgressProps) => {
  return (
    <>
      <div
        className={classNames(
          'absolute left-0 top-0 bottom-0 z-0 mx-auto w-2 rounded-full md:inset-0',
          BACKGROUND_THEME_MAPS[theme],
        )}
      />
      <div
        className={classNames(
          'absolute top-0 left-0 z-10 mx-auto w-2 rounded-full md:left-0 md:right-0',
          FOREGROUND_THEME_MAPS[theme],
        )}
        style={{ height: `${progress}%` }}
      />
    </>
  )
}

export default TimelineProgress
