import Props from '@common/Props.interface'
import TimelineProgress from '@components/blocks/Timeline/TimelineProgress'
import { Size, Theme } from '@components/shared'
import Icon from '@icons/Icon'
import classNames from '@utils/classNames'

import styles from './Timeline.module.scss'
import TimelineEvent from './TimelineEvent'

export interface TimelineProps extends Props {
  events: TimelineEvent[]
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const Timeline = ({ className = '', theme = Theme.ORANGE, events }: TimelineProps) => {
  const progress =
    (events.filter((event) => event.active).length / events.length) *
    (((events.length - 1) * 100) / events.length)

  return (
    <div className={`relative ${className}`}>
      <div className={styles.timeline}>
        {events.map((event) => {
          const iconSize = event.date ? Size.SMALLER : Size.SMALL
          const TheIcon = <Icon icon={event.icon} theme={theme} size={iconSize} />

          const IconAndDate = event.date ? (
            <div className="mb-3 flex w-full items-center justify-between">
              <div className={classNames('text-xs font-bold', THEME_MAPS[theme])}>{event.date}</div>
              {TheIcon}
            </div>
          ) : (
            TheIcon
          )

          return (
            <div
              key={`event-${event.id}`}
              className={classNames(
                styles.event,
                event.active ? styles.active : null,
                event.disabled ? styles.disabled : null,
                event.date ? styles.date : null,
              )}
            >
              {IconAndDate}
              <div className="mt-3 text-xl font-bold">{event.heading}</div>
              <p className="text-sm">{event.description}</p>
            </div>
          )
        })}
      </div>
      <TimelineProgress progress={progress} theme={theme} />
    </div>
  )
}

export default Timeline
